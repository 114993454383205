.MuiLoadingButton-root {
    background-color: #E6D41E !important;
    color: black !important;

    &:hover {
      background-color: #8a8011 !important;
    }
  }

  .MuiButton-root {
    background-color: #E6D41E !important;
    color: black !important;

    &:hover {
      background-color: #8a8011 !important;
    }
  }

  .MuiInput-root {
    color:#E6D41E !important;
    &:after {
      border: none !important;
    }
  }

  .MuiInput-underline:before {
    border-bottom: 2px solid #E6D41E !important;
  }

  .MuiInputBase-root:hover:not(.Mui-disabled):before {
    border-bottom: 2px solid #E6D41E !important;
  }

  label.Mui-focused {
    color: #E6D41E !important;
  }

  label.Mui-focused::before {
    color: #E6D41E !important;
  }
  
  label.Mui {
    color: #E6D41E !important;
  }

  .MuiInput-underline:after {
    border-bottom: 2px solid #E6D41E !important;
  }